
/* RENAMED TO Slider.js */
/* RENAMED TO Slider.js */
/* RENAMED TO Slider.js */

import Field from "./controls.filter.Field.js";

export default class FieldRange extends Field {
    constructor(options) {
        super(options);
    }

    loadStorage() {
        super.loadStorage();
    }


    /* */
    setTempValue(value) {
        this.temp = value;
    }

    applyTempValue() {

    }


    getPills() {
        /* TODO: FIX */
        return [];
    }

    getFilterText() {
        /* TODO: FIX */
        return "N/A";
    }

    getFilterString() {
        /* TODO: FIX */
        return "";
    }

    applyTempValue() {
        /* TODO: FIX */
    }
}
